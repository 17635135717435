<template>
  <WidgetFrame @onResize="onResize">
    <template v-slot:title>
      My Work Schedule
    </template>
    <template v-slot:content>
      <template v-if="!canView('STAFF')">
        <div class="center-text">
          {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
        </div>
      </template>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else-if="noSchedule">
        <div class='status-message'>No schedule</div>
      </template>
      <template v-else>
        <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { AgChartsVue } from 'ag-charts-vue';
import { formatDate } from '@/helpers';
import { staffService } from '@/services';
import settings from "@/_dashboardSettings";
import cColors from "@/_chartColors";
import { EventBus, calculateStaffUsage, processSystemCalendar } from '@/helpers';
import { tooltipRenderer } from '@/helpers/ag-chart-tooltip-renderer';

const fullMonthNames = [ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December" ];
const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export default {
  name: 'HomeMyScheduleWidget',
  components: {
    WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    AgChartsVue,
  },
  props: {
    userEmail: { type: String, default: null }
  },
  data: function() {
    return {
      width: 0,
      height: 0,
      loaded: false,
      options: null,
      noSchedule: false,

      data: [], // for counting working days
      staffData: [], // for counting number of staff
      staffUuId: null,
      usages: null,
      maxStaffVal: 0,
      maxVal: 0
    }
  },
  created() {
    EventBus.$on('theme-change', () => {
      if (this.loaded) {
        this.buildChart();
      }
    });
    
    if (this.canView('STAFF')) {
      this.buildData();
    }
  },
  beforeDestroy() {
    EventBus.$off('theme-change');
  },
  methods: {    
    buildParams() {
      const params = {
        start: 0,
        limit: -1,
      };
      params.filter = [
        '_and_', [
          ['STAFF.email', 'eq', this.userEmail]
        ]
      ];
      return params;
    },
    buildData() {
      // Get the staff uuId from their email (the user uuId differs from the staff uuId)
      const self = this;
      staffService.list(this.buildParams()).then(async resp => {
        if (resp.data.length == 0) {
          self.noSchedule = true;
        } else {
          self.staffUuId = resp.data.map(d => { return { uuId: d.uuId }});
          await self.getStaffUsage();
          self.processUsage();
          self.buildChart();
        }
        self.loaded = true;
      });
    },
    buildChart() {
      // Convert the months to text names
      let monthNames = this.width < 550 ? shortMonthNames : fullMonthNames;
      for (var i = 0; i < this.data.length; i++) {
        this.data[i].monthName = monthNames[this.data[i].month];
      }
      
      this.options = {
        data: this.data,
        background: {
          fill: '#00000000', // Transparent in every theme
        },
        series: [
          {
            type: 'area',
            xKey: 'monthName',
            yKeys: ['days'],
            yNames: ['Days'],
            marker: {
              enabled: true,
              size: 6,
            },
            //strokes: settings.getChartFills(),
            fills:  settings.getChartFills(),
            tooltip: {
              enabled: true,
              renderer: tooltipRenderer
            }
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              rotation: 0,
              color: cColors.getThemeColor('text-medium'),
              fontFamily: 'Roboto'
            },
            gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }]
          },
          {
            type: 'number',
            position: 'left',
            label: {
              color: cColors.getThemeColor('text-medium')
            },
            gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }],
            title: {
              enabled: true,
              text: 'Days',
              color: cColors.getThemeColor('text-medium')
            }
          },
        ],
        legend: { enabled: false },
      };

      if (this.width < 380) {
        this.options.axes[0].label.rotation = -37;
        this.options.padding = {left: 0, right: 0};
      }
    },
    processUsage() {
      const b = new Date();
      b.setMonth(b.getMonth() - 3);
      const u = new Date();
      u.setMonth(u.getMonth() + 3);
      this.data.length = 6;
      const iter = new Date(b);
      var order = [];
      while (iter <= u) {
        order.push(iter.getMonth());
        iter.setMonth(iter.getMonth() + 1);
      }

      var staffCount = [];
      for (var prop in this.usages) {
        var day = 0;
        for (var keyalloc of Object.keys(this.usages[prop].resourceAllocationList)) {
          const alloc = this.usages[prop].resourceAllocationList[keyalloc];
          const matches = keyalloc.match(/(.+?)-(.+?$)/);          
          const monthIndex = matches !== null ? parseInt(matches[2]) - 1 : 0; // zero based index
          const index = order.indexOf(monthIndex);
          if (typeof this.data[index] === 'undefined') {
            this.data[index] = { month: monthIndex, days: 0};
            this.staffData[index] = { month: monthIndex, days: 0};
          }
          
          this.data[index].days += alloc.w/8; // Assuming an 8-hour workday, like backend
          if (alloc.w != 0) {
            if (typeof staffCount[index] === 'undefined') {
              staffCount[index] = {};
            }
            staffCount[index][prop] = true;
          }
          
          if (this.data[index].days > this.maxVal) {
            this.maxVal = this.data[index].days;
          }
        }
      }
      
      for (var index in staffCount) {
        this.staffData[index].days = Object.keys(staffCount[index]).length;
        if (this.staffData[index].days > this.maxStaffVal) {
          this.maxStaffVal = this.staffData[index].days;
        }
      }
    },
    async getStaffUsage() {
      const self = this;
      const b = new Date();
      b.setMonth(b.getMonth() - 3);
      const u = new Date();
      u.setMonth(u.getMonth() + 3);

      var params = { begin: formatDate(b), until: formatDate(u) };
      if (this.canView('STAFF')) {
        await staffService.usage(params, false, this.staffUuId).then(response => {
          const data = response.data[response.data.jobCase];  
          const entityList = response.data['entityList'];
          const baseCalendar = processSystemCalendar(entityList['00000000-0000-0000-0000-000000000000'].calendarList);
          
          for (let j = 0; j < data.length; j++) {
            // prepare calendar lists
            const locationUuId = data[j].locationList.length > 0 ? data[j].locationList[0].uuId : null;
            const locationCalendar = locationUuId !== null ? entityList[locationUuId].calendarList : null;
            const calendarList = data[j].calendarList;
            const calendars = [ calendarList, locationCalendar, baseCalendar ];
            // populate the tasks from the dictionary
            if (data[j].taskList) {
              for (const task of data[j].taskList) {
                for(var k in entityList[task.uuId]) task[k]=entityList[task.uuId][k];
              }
            }
            data[j].resourceAllocationList = calculateStaffUsage(data[j], moment(b), moment(u), 'month', calendars);
          }
          self.usages = data;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });
      }
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
      if (this.loaded) {
        this.buildChart();
      }
    }
  },
}
</script>
